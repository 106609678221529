


















import {Component} from 'vue-property-decorator';
import {readFoods} from '@/store/crud/getters';
import {dispatchDeleteFood, dispatchGetFoods} from '@/store/crud/actions';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import FoodsTable from '@/components/tables/FoodsTable.vue';

@Component({components: {ConfirmationModal, FoodsTable}})
export default class Foods extends AppComponent {
  get foods() {
    const foods = readFoods(this.$store);
    if (foods) {
      return foods;
    } else {
      return [];
    }
  }
  public fetchingFoodsData = false;
  /**
   * created hook, it only calls async fetch data
   */
  public created() {
    this.fetchData();
  }

  /**
   * fetches data asyncrhonously from store / server
   * and displays loading indicator
   */
  public async fetchData() {
    this.fetchingFoodsData = true;
    await this.$nextTick();
    await dispatchGetFoods(this.$store);
    this.fetchingFoodsData = false;
  }

  /**
   * Delete a food and refresh the screen
   */
  public async deleteFood(foodId: string) {
    await dispatchDeleteFood(this.$store, {foodId});
    await this.fetchData();
  }
}
